export const MENU_LIST = [
  { title: "Trang chủ", path: "/" },
  { title: "Tính năng", path: "/tinh-nang" },
  { title: "Đối tác", path: "/doi-tac" },
  {
    title: "Khuyến mãi",
    children: [
      {
        title: "Khuyến mãi đang diễn ra",
        path: "/khuyen-mai",
      },
      {
        title: "Khuyến mãi đã kết thúc",
        path: "/khuyen-mai-da-ket-thuc",
      },
    ],
  },
  { title: "Liên kết ngân hàng", path: "/lien-ket-ngan-hang" },
  {
    title: "Tài nguyên",
    children: [
      {
        title: "Demo cho đối tác",
        path: "https://shopeepay.vn/merchant-demo",
      },
      {
        title: "Hướng dẫn tích hợp",
        path: "https://product.shopeepay.com/",
      },
    ],
  },
  {
    title: "Trung tâm trợ giúp",
    path: "https://help.cs.shopeepay.vn/portal?source=100",
  },
];

export const EXTRA_BUTTON = {
  title: "Tải ứng dụng",
  path: "/tai-ung-dung",
};
