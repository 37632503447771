import { 
  CLICKED_FOOTER_MERCHANT_FEATURES ,
  CLICKED_FOOTER_TABS_NOT_ON_HEADER,
  PROMO_BANNER_FETCH_DATA_REQUEST,
  PROMO_BANNER_FETCH_DATA_SUCCESS,
  PROMO_BANNER_FETCH_DATA_ERROR,
} from "../Types";

const currentFooterMfInitialState = {};
const currentFooterClickedInitialState = {};
const PromoBannerPageReducerInitialState = {};

export const currentClickedFooterReducer = (
  state = currentFooterMfInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case CLICKED_FOOTER_MERCHANT_FEATURES : {
      return {
        ...state,
        clickedMF: payload,
      };
    }
    default:
      return state;
  }
};

export const currentFooterClickedReducer = (
  state = currentFooterClickedInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case   CLICKED_FOOTER_TABS_NOT_ON_HEADER : {
      return {
        ...state,
        clickedNotOnHeader: payload,
      };
    }
    default:
      return state;
  }
};

export const PromoBannerPageReducer = (
  state = PromoBannerPageReducerInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PROMO_BANNER_FETCH_DATA_REQUEST: {
      return {
        loading: true,
      };
    }
    case PROMO_BANNER_FETCH_DATA_SUCCESS: {
      return {
        loading: false,
        success: true,
        promoBanner: payload,
      };
    }
    case PROMO_BANNER_FETCH_DATA_ERROR: {
      return {
        loading: false,
        success: false,
        promoBanner: payload,
      };
    }
    default:
      return state;
  }
};

