import { combineReducers } from "redux";

// Reducers
import * as HomePageReducer from "src/Components/HomePage/Redux/Reducer";
import * as MenuSelectionBarReducer from "src/Common/Components/MenuSelectionBar/Redux/Reducer";
import * as RegionLanguageReducer from "src/Common/Redux/Reducer";
import * as searchBarReducer from "src/Components/FAQPage/Redux/Reducer";
import * as bankLinksReducer from "src/Components/LinkBankPage/Redux/Reducer";
import * as currentUrlPageReducer from "src/RootRedux/UrlRedux/Reducer";

export const combinedReducers = combineReducers({
  // HomePage
  ...HomePageReducer,
  ...MenuSelectionBarReducer,
  ...RegionLanguageReducer,
  ...searchBarReducer,
  ...bankLinksReducer,
  ...currentUrlPageReducer,
});
